<template>
    <v-dialog
        v-model="app"
        @click:outside="abort"
        width="600"
    >
      <v-card>
        <v-list>

          <v-list-item two-line>

            <v-list-item-avatar
              v-if="app.icon !== null && app.icon.url !== null"
              size="60"
            >
              <v-img :src="app.icon.url"></v-img>
            </v-list-item-avatar>

            <v-list-item-avatar v-else size="60" class="grey">

            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                {{ app.name }} 
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ app.description }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <span class="grey--text text--darken-1" >{{ applicationAuthor }}</span> 
              </v-list-item-subtitle>
            </v-list-item-content>

          </v-list-item>

        </v-list>

        <v-card-text v-if="app.defines === null || app.defines.configFields === null || app.defines.configFields === undefined || app.defines.configFields.length === 0">
          {{this.$t("appDoesNotRequireConfiguration")}}
        </v-card-text>

        <v-card-text v-if="app.defines !== null && app.defines.configFields !== null && app.defines.configFields.length > 0">
          <v-container>
            <v-form ref="onBoardingAppForm">
              <template v-for="configField in app.defines.configFields">
                <v-text-field
                        filled
                        :key="configField.key"
                        :label="configField.label"
                        v-if="configField !== null && configField.type === 'textfield'"
                        :required="configField.required ? 'required' : ''"
                        v-model="onBoardingData[configField.key]"
                        :placeholder="configField.placeholder"
                        :hint="configField.description"
                />
                <v-text-field
                        type="password"
                        filled
                        :key="configField.key"
                        :label="configField.label"
                        v-if="configField !== null && configField.type === 'password'"
                        :required="configField.required ? 'required' : ''"
                        v-model="onBoardingData[configField.key]"
                        :placeholder="configField.placeholder"
                        :hint="configField.description"
                />
                <v-select
                        filled
                        :key="configField.key"
                        :label="configField.label"
                        v-if="configField !== null && configField.type === 'select'"
                        :required="configField.required ? 'required' : ''"
                        v-model="onBoardingData[configField.key]"
                        :placeholder="configField.placeholder"
                        :hint="configField.description"
                        :items="configField.options"
                        item-value="key"
                        item-text="label"
                />
                <tiptap-vuetify
                        :key="configField.key"
                        :label="configField.label"
                        v-if="configField !== null && configField.type === 'textarea'"
                        :required="configField.required ? 'required' : ''"
                        v-model="onBoardingData[configField.key]"
                        :extensions="extensions"
                        :placeholder="configField.placeholder"
                        :card-props="{ flat: true, color: '#f5f5f5', border: '1px solid #ccc' }"
                />
                <v-switch
                        :key="configField.key"
                        :label="configField.label"
                        v-if="configField !== null && configField.type === 'checkbox'"
                        v-model="onBoardingData[configField.key]"
                        :hint="configField.description"
                />
              </template>
            </v-form>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text color="red" @click="abort">{{$t('abort')}}</v-btn>
          <v-spacer></v-spacer>
          <v-btn
                  color="primary"
                  dark

                  :disabled="isInstallingApp"
                  :loading="isInstallingApp"

                  @click="installApp"
          >
          {{ applicationInstallText }}
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
</template>
<script>


export default {
    name: 'AppInstallDialog',
    props: {
        app: {
          type: Object,
          default: () => {},
          required: true
        },
        abort: {
            type: Function,
            default: () => {},
            required: false
        },
        latestAppVersion: {
            type: String,
            default: () => null,
            required: false
        }
	},
    data: function() {
        return {
            onBoardingData: {}
        }
    },
    computed: {
      applicationAuthor() {
        let returnString = " | Free"

        if (this.app.price !== null && this.app.price !== undefined && this.app.price.amount > 0) {
          returnString = ' | ' + this.app.price.amount + ' ' + this.app.price.currency_code
        }

        if (this.app?.roles?.owner?.displayName && !this.app.roles?.owner?.email?.includes('@salescloud.is')) {
          return 'Author: ' + this.app.roles?.owner?.displayName + " " + returnString
        }

        return 'Author: SalesCloud' + returnString
      },
      applicationInstallText() {
        if(this.app.price === null || this.app.price.amount === 0) {
				  return 'Install'
			  }

			  return 'Try for 30 days'
      },
      isInstallingApp() {
        return this.$store.state.installingApps.includes(this.app.uuid)
      },
      applicationResult() {
        return this.$store.state.installAppResult
      }
    },
    methods: {
        installApp() {
            if(this.$refs.onBoardingAppForm !== undefined && !this.$refs.onBoardingAppForm.validate()) {
                return
            }

            this.app.data = JSON.stringify(this.onBoardingData)

            this.app.version = this.latestAppVersion.toString()

            this.$store.dispatch('installApp', this.app)
        },
    },
    mounted() {
	},
}
</script>