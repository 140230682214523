<template>
    <v-dialog value="true" width="unset">
        <v-card class="text-center green" dark>
        <v-card-title class="text-center pt-10 pb-5" style="text-align: center !important; display: block;">
          {{$t('successfullInstall')}}
        </v-card-title>
        <v-card-subtitle class="pb-10">
          {{$t('successfullInstallSubText')}}
        </v-card-subtitle>
        <v-card-text>
          <v-simple-table style="background: rgba(0,0,0,0.5)">
            <tbody>
              <tr>
                <td>{{$t('install')}} ID</td>
                <td>{{ installAppResult.installUUID }}</td>
              </tr>
              <tr>
                <td>{{$t('code')}}</td>
                <td>{{ installAppResult.code }}</td>
              </tr>
              <tr>
                <td>{{$t('message')}}</td>
                <td style="white-space: pre">{{ installAppResult.message }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-btn block color="pink" @click="closeInstallResult">{{$t('close')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'AppInstallSuccess',
    props: {
      abort: {
        type: Function,
        required: true
      }
    },
    methods: {
        closeInstallResult() {
            this.$store.commit('setInstallAppResult', null)
        }
    },
    computed: {
        installAppResult() {
            return this.$store.state.installAppResult
        }
    },
    mounted() {
      this.abort()
    }
}
</script>