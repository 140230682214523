<template id="app-roles">
    <v-container grid-list-xl style="height: 100%;">

        <AppInstanceEditDialog v-if="showUpdateDialog" :app="app" :abort="abortUpdate" :appInstanceUUID="currentInstanceUUID" :appInstanceSettings="currentInstanceSettings" />

        <ConfirmRemoveDialog
            v-model="showRemoveDialog"
            v-if="showRemoveDialog"
            @closed="abortRemove"
            @confirmed="uninstallAppInstance"
            :processing="processingDelete"
            :message="$t('confirmRemoveAppInstance')" 
        />
        
        <v-layout align-center justify-center fill-height>

            <v-card flat outlined class="mt-3" style="width:100%;"> 

                <v-card-title>{{ $t('instances') }}</v-card-title>

                <v-card-text v-if="appInstances.length === 0">{{ $t('noAvailableInstances') }}</v-card-text>

                <v-list v-else>
                        <v-divider></v-divider>

                        <template v-for="(instance) in appInstances">

                            <v-list-item v-bind:key="instance.uuid">

                                <v-list-item-content>
                                    <v-list-item-title class="mt-2"><b>{{ $t('version') }}: </b>{{instance.version}}</v-list-item-title>

                                    <v-list-item-title class="mt-2"><pre>{{instance.settings}}</pre></v-list-item-title>

                                </v-list-item-content>

                                <v-btn class="mr-3" :loading="processingEdit">
                                    <v-icon small>mdi-pencil</v-icon>
                                    <div class="ml-2" @click="openUpdateDialog(instance.uuid, instance.settings)">{{$t('edit')}}</div>
                                </v-btn>

                                <v-btn class="mr-3" @click="updateApp(instance.uuid)" color="success" :loading="processingUpdate"> 
                                    <v-icon small>mdi-update</v-icon>
                                    <div class="ml-2">{{$t('update')}}</div>
                                </v-btn>

                                <v-btn class="mr-3" @click="openRemoveDialog(instance.uuid)" color="error" :loading="processingDelete">
                                    <v-icon small>mdi-delete</v-icon>
                                    <div class="ml-2">{{ $t('uninstall') }}</div>
                                </v-btn>
                            </v-list-item>

                        </template>

                </v-list>

            </v-card>

        </v-layout>
    </v-container>
</template>

<script>

import AppInstanceEditDialog from "@/components/apps/AppInstanceEditDialog"
import ConfirmRemoveDialog from "@/components/common/ConfirmRemoveDialog"

export default {
    components: {
		AppInstanceEditDialog,
        ConfirmRemoveDialog
    },
	data: function() {
		return {
            processingDelete: false,
            processingUpdate: false,
            processingEdit: false,
            showUpdateDialog: false,
            showRemoveDialog: false,
            currentInstanceUUID: null,
            currentInstanceSettings: {}
		}
	},
    props: {
		app: {
			type: Object,
			default: () => {},
			required: true
		}
    },
	computed: {
        appInstances() {
			return this.$store.getters.getAppInstances(this.app.uuid)
		},
	},
    methods: {
        openRemoveDialog(instanceUUID) {
            this.currentInstanceUUID = instanceUUID
            this.showRemoveDialog = true
        },
        abortRemove() {
            this.currentInstanceUUID = null
            this.showRemoveDialog = false
        },
        uninstallAppInstance() {
            this.processingDelete = true

            this.$store.dispatch('uninstallAppInstance', this.currentInstanceUUID).then((res) => {
                if (res && res.success) {
                    this.$store.commit('updateActionSuccess', {
                        message: 'Success',
                        subMessage: res.message
                    })
                }else {
                    this.$store.commit('updatreActionError', {
                        message: 'Failure',
                        subMessage: res.message
                    })
                }
            }).catch(e => {
                this.$store.commit('updateActionError', {
                    message: 'Failure',
                    subMessage: 'Unknown error occured while trying to uninstall app instance.'
                })
            }).finally(() => {
                this.processingDelete = false
                this.showRemoveDialog = false
                this.currentInstanceUUID = null
            })
        },
        openUpdateDialog(instanceUUID, instanceSettings) {
            this.currentInstanceSettings = instanceSettings
            this.currentInstanceUUID = instanceUUID
            this.showUpdateDialog = true
        },
        abortUpdate() {
            this.currentInstanceSettings = {}
            this.currentInstanceUUID = null
            this.showUpdateDialog = false
        },
        updateApp(instanceId) {
            this.processingUpdate = true

            this.$store.dispatch('updateApp', instanceId).then((res) => {

                if (res && res === true) {
                    this.$store.commit('updateActionSuccess', {
						message: 'Success',
						subMessage: 'Successfully updated the app instance version.'
					})
                }else {
                    this.$store.commit('updateActionError', {
                        message: 'Failure',
                        subMessage: 'Unknown error occured while trying to update app instance.'
                    })
                }
            }).catch(e => {
                this.$store.commit('updateActionError', {
                    message: 'Failure',
                    subMessage: 'Unknown error occured while trying to update app instance.'
                })
            }).finally(() => {
                this.processingUpdate = false
            }) 
        },
    },
    watch: {

    },
    mounted() {

	},
}
</script>