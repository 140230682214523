<template id="app-roles">
    <v-container grid-list-xl style="height: 100%;">
        
        <v-layout align-center justify-center fill-height>

            <v-card flat outlined class="mt-3" style="width:100%;" v-if="!gettingAppReleases"> 

                <v-card-title>{{$t('releases')}}</v-card-title>

                <v-card-text v-if="currentReleases !== null && currentReleases !== undefined && currentReleases.length === 0">{{$t('noReleases')}}</v-card-text>

                <v-list v-else>
                        <v-divider></v-divider>

                        <template v-for="(release) in currentReleases">

                            <v-list-item v-bind:key="release.version">

                                <v-list-item-content>
                                    <v-list-item-title>{{release.version}}</v-list-item-title>
                                    <v-list-item-subtitle class="mt-2"><span class="text--primary"> {{ new Date(release.created).toLocaleString("is-IS") }} </span> - {{release.changeNotes}}</v-list-item-subtitle>
                                </v-list-item-content>
                            
                            </v-list-item>

                            <!-- <v-divider></v-divider> -->

                        </template>

                </v-list>

            </v-card>

            <v-card flat outlined class="mt-3" style="width:100%;" v-else> 

                <v-card-title>{{$t('releases')}}</v-card-title>
                <template v-for="index in 5">
                <v-skeleton-loader
                    ref="skeleton"
                    type="list-item-avatar-two-line"
                    class="mx-auto"
                    :key="index"
                />
                </template>
            </v-card>

        </v-layout>
    </v-container>
</template>

<script>

export default {
	data: function() {
		return {
			release: {
				changeNotes: null,
			}
		}
	},
    props: {
		app: {
			type: Object,
			default: () => {},
			required: true
		},
    },
	computed: {
        gettingAppReleases() {
            return this.$store.state.gettingAppReleases
        },
        currentReleases() {
			return this.$store.state.currentReleases
		}
		// version: function() {
			
		// 	let version = this.app.version
            
		// 	if(version === null || version === undefined) {
		// 		version = 'dev'
		// 	}

		// 	return version === 'dev' ? 1 : parseInt(version) + 1
		// },
	},

}
</script>