<template>
    <v-dialog
        value="true"
        width="500"
    >
      <v-card :color="color" class="text-center" dark>
        <v-card-title class="text-center pt-10 pb-5" style="text-align: center !important; display: block;">
          {{ title }}
        </v-card-title>
        <v-card-subtitle class="pb-10">
          {{ description }}
        </v-card-subtitle>
        <v-card-text>
          <v-simple-table style="background: rgba(0,0,0,0.5)">
            <tbody>
            <tr>
              <td>{{$t('install')}} ID</td>
              <td>{{installAppResult.installUUID}}</td>
            </tr>
            <tr>
              <td>{{$t('code')}}</td>
              <td>{{installAppResult.code}}</td>
            </tr>
            <tr>
              <td>{{$t('message')}}</td>
              <td>{{installAppResult.message}}</td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-btn block color="white" light @click="closeInstallResult">{{$t('close')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'AppInstallFailed',
    methods: {
        closeInstallResult() {
            this.$store.commit('setInstallAppResult', null)
        }
    },
    computed: {
        title() {

          if(this.installAppResult.redirectUrl !== null && this.installAppResult.redirectUrl !== undefined) {
            return 'Complete Install Remotely'
          }

          return 'Install Failed'
        },
        description() {

          if(this.installAppResult.redirectUrl !== null && this.installAppResult.redirectUrl !== undefined) {
            return 'Redirecting you to remote install...'
          }

          return 'Your app failed to install.'
        },
        color() {

          if(this.installAppResult.redirectUrl !== null && this.installAppResult.redirectUrl !== undefined) {
            return 'blue'
          }

          return 'red'
        },
        installAppResult() {
            return this.$store.state.installAppResult
        }
    },
    watch: {
      installAppResult(newResult) {
        if(newResult.redirectUrl !== null && newResult.redirectUrl !== undefined) {
          document.location = newResult.redirectUrl
        }
      }
    },
    mounted() {
      if(this.installAppResult.redirectUrl !== null && this.installAppResult.redirectUrl !== undefined) {
          document.location = this.installAppResult.redirectUrl
        }
    }
}
</script>