<template>
  <v-container>
    <v-card class="mb-3">
      <AppInstallSuccessDialog
        v-if="installAppResult !== null && installAppResult !== undefined && installAppResult.success"
        :abort="closeInstallDialog"
      />
      <AppInstallFailedDialog
        v-if="installAppResult !== null && installAppResult !== undefined && !installAppResult.success"
        :abort="closeInstallDialog"
      />

      <AppInstallDialog
        v-if="showInstallDialog"
        :app="app"
        :abort="closeInstallDialog"
        :latest-app-version="latestAppVersion"
      />

      <v-card-title>
        {{ app.name }}
        <v-spacer />
        <div class="text-center">
          {{ applicationPrice }}
        </div>
      </v-card-title>
      <v-card-subtitle>{{ app.description }}</v-card-subtitle>
      <v-card-actions>
        <v-btn
          block
          depressed
          class="primary"
          :loading="gettingAppReleases"
          :disabled="canInstallApp"
          @click="openInstallDialog"
        >
          {{ installButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-tabs v-model="tab">
      <v-tab href="#information">
        {{ this.$t('information') }}
      </v-tab>
      <v-tab href="#instances">
        {{ this.$t('instances') }}
      </v-tab>
    </v-tabs>

    <v-tabs-items
      class="grey lighten-3"
      v-model="tab"
    >
      <v-tab-item value="information">
        <AppReleases :app="app" />
      </v-tab-item>

      <v-tab-item value="instances">
        <AppInstances :app="app" />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import AppInstallDialog from "@/components/apps/AppInstallDialog"
import AppReleases from "@/components/apps/AppReleases"
import AppInstances from "@/components/apps/AppInstances"

import AppInstallSuccessDialog from '@/components/apps/AppInstallSuccessDialog'
import AppInstallFailedDialog from '@/components/apps/AppInstallFailedDialog'

export default {
	name: 'AppProfile',
	components: {
		AppInstallDialog,
		AppReleases,
		AppInstances,
		AppInstallSuccessDialog,
		AppInstallFailedDialog
    },
	data: () => ({
		showInstallDialog: false,
		releases: [],
		tab: null,
    }),
	computed: {
		applicationPrice() {
			if (this.app !== null && this.app !== undefined && this.app.price !== undefined && this.app.price !== null && this.app.price.amount !== null && this.app.price.amount !== undefined && this.app.price.amount > 0) {
				return this.app.price.amount + " " + this.app.price.currency_code
			}

			return 'Free'
		},
		gettingAppReleases() {
            return this.$store.state.gettingAppReleases
        },
		currentReleases() {
			return this.$store.state.currentReleases
		},
		canInstallApp() {
			if (this.currentReleases !== null && this.currentReleases !== undefined && Array.isArray(this.currentReleases) && this.currentReleases.length > 0) {
				return false
			}

			return true
		},
		latestAppVersion() {
			return this.currentReleases.at(-1).version
		},
		installAppResult() {
			return this.$store.state.installAppResult
		},
		installButtonText() {
			if (!this.canInstallApp) {
				return "Install"
			}

			return 'No availible version'
		}
	},
	watch: {

	},
	props: {
		app: {
			type: Object,
			default: () => {},
			required: true
		}
	},
	methods: {
		openInstallDialog() {
			this.showInstallDialog = true
		},
		closeInstallDialog() {
			this.showInstallDialog = false
		},
	},
}
</script>
